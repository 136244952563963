import { useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { useWindowScroll } from "../hooks/useWindowScroll"
import { colors } from "../styles/colors"
import { LanguageSwitch } from "./LanguageSwitch"
import { LoginMenu } from "./LoginMenu"
import styles from './TopMenu.module.css'

const HEADER_MIN_POSITION_X = 280
const ANIMATION_DELAY_MS = 200

export const TopMenu = () => {
  const history = useHistory()
  const location = useLocation()
  const { positionX } = useWindowScroll()
  const [headerOpacity, updateHeaderOpacity] = useState(0)
  const headerShouldBeFixed = useMemo(() => positionX > HEADER_MIN_POSITION_X, [positionX])
  const isUserPage = useMemo(() => location.pathname === '/user', [location])

  useEffect(() => {
    if (headerShouldBeFixed === true) {
      updateHeaderOpacity(0)
      setTimeout(() => updateHeaderOpacity(1), ANIMATION_DELAY_MS * 2)
    } else {
      updateHeaderOpacity(1)
    }
  }, [headerShouldBeFixed])

  return (
    <header style={{
      position: headerShouldBeFixed ? 'fixed' : 'relative',
      opacity: headerOpacity,
      transition: headerOpacity === 1 ? `opacity ${ANIMATION_DELAY_MS}ms linear` : 'none',
      padding: '0.5rem 0'
    }}>
      <div
        className={styles.menuInnerContainer}
        onClick={() => history.push('/')}
      >
        {(headerShouldBeFixed || isUserPage) &&
          <>
            <img src={'/images/logo.svg'} alt={'regentoren-logo'} style={{ height: '2.5rem', width: 'auto' }} />
            <p className={styles.leftMenuLogo} style={{ color: colors.primary }}>regentoren</p>
          </>
        }
      </div>
      <div className={styles.menuInnerContainer}>
        <LoginMenu />
        <LanguageSwitch />
      </div>
    </header>
  )
}
