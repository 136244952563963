const AAA_BASE_URL = process.env.REACT_APP_AAA_BASE_URL
const PUBLIC_BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL
const SRB_SENSORSYSTEM_TYPE_ID = 1
const REGENTOREN_SENSORSYSTEM_ID = 253370

const getRequest = (url: string) => {
  return fetch(url,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

const postRequest = (url: string, body?: object) => {
  return fetch(url,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body ? body : {})
    }
  )
}

export const getAllSrb = () => getRequest(`${AAA_BASE_URL}/cons/getAllRegisteredBuffers`)

export const getAllUserSrbs = (uuid: string) => getRequest(`${PUBLIC_BASE_URL}/getUserBuffers/${uuid}`)

export const getSingleSrb = (id: string) => getRequest(`${AAA_BASE_URL}/cons/sensorsystem/${id}`)

export const getAllSrbPosition = () => getRequest(`${AAA_BASE_URL}/cons/typelocations/${SRB_SENSORSYSTEM_TYPE_ID}`)

export const getRegentorenInformation = () => getRequest(`${PUBLIC_BASE_URL}/getRegentorenInfo`)

export const updateSrbAlias = (sensorSystemId: number, newAlias: string) => postRequest(`${AAA_BASE_URL}/prod/updatealias/${sensorSystemId}/${newAlias}`)

export const updateZipcodeAndLocation = (sensorSystemId: number, zipcode: string) => postRequest(`${AAA_BASE_URL}/updatezipcodeandlocation/${sensorSystemId}/${zipcode}`)

export const updateCapacityCharacteristic = (sensorSystemId: number, newCapacity: number) => postRequest(`${AAA_BASE_URL}/prod/updatecharacteristic/${sensorSystemId}/capacity/${newCapacity}`)

export const postNewUserDischarge = (deviceId: number, formattedDateTime: string, dischargeUntilPercentage: number) => {
  return postRequest(`${PUBLIC_BASE_URL}/registeruserdischarge/${deviceId}/${dischargeUntilPercentage}/${formattedDateTime}`)
}

export const getAllDischargeEvents = (deviceId: number) => {
  return getRequest(`${PUBLIC_BASE_URL}/getAllDischargeEvents/${deviceId}`)
}

export const deleteSingleDischarge = (eventId: number) => postRequest(`${AAA_BASE_URL}/prod/deletedischarge/${eventId}`)