import { useEffect, useMemo, useState } from 'react'
import AwesomeSlider, { AwesomeSliderInfo } from 'react-awesome-slider'
import { useTranslation } from 'react-i18next'
import { useScreenDimensions } from '../hooks/useScreenDimensions'

const EMPTY_CAPTION = 'empty'

const captionStyle: React.CSSProperties = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'rgb(114 160 197 / 70%)',
    padding: '0.5rem',
    borderRadius: '0.25rem',
    zIndex: 99
}

export const MediaGallery = () => {
    const { t } = useTranslation('common')
    const INITIAL_CAPTION = t('media.slide-1')

    const { isSmartphone } = useScreenDimensions()
    const [currentCaption, updateCurrentCaption] = useState('')
    const captionResponsiveStyle: React.CSSProperties = useMemo(() => {
        return {
            ...captionStyle,
            top: isSmartphone ? '75%' : '90%',
            width: isSmartphone ? '70vw' : 'unset'
        }
    }, [isSmartphone])

    useEffect(() => {
        if (INITIAL_CAPTION === '' || INITIAL_CAPTION === 'media.slide-1') return
        updateCurrentCaption(INITIAL_CAPTION)
    }, [INITIAL_CAPTION])

    return (
        <div style={{ margin: '1rem 0 6rem', position: 'relative' }}>
            <AwesomeSlider
                onTransitionEnd={(e: AwesomeSliderInfo) => {
                    // @ts-ignore
                    if (!e || !e.currentMedia || !e.currentMedia.caption) return
                    // @ts-ignore
                    updateCurrentCaption(e.currentMedia.caption)
                }}
                media={[
                    {
                        source: '/images/first_prototype_2.jpg',
                        caption: INITIAL_CAPTION
                    },
                    {
                        source: '/images/preparing-srb_3.jpg',
                        caption: t('media.slide-2')
                    },
                    {
                        source: '/images/srb-2_3.jpg',
                        caption: t('media.slide-3')
                    },
                    {
                        source: '/images/srb-3_2.jpg',
                        caption: t('media.slide-4')
                    },
                    // {
                    //     source: '/videos/enschede_campaign_video.mp4',
                    //     caption: EMPTY_CAPTION
                    // }
                ]}
            />
            {currentCaption !== EMPTY_CAPTION && <p style={captionResponsiveStyle}>{currentCaption}</p>}
        </div>
    )
}