import { Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import 'react-awesome-slider/dist/styles.css'
import 'react-toastify/dist/ReactToastify.css'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './styles/index.css'
import './utils/language'
import { ToastContainer } from 'react-toastify'
import { AppWrapper } from './context/AppContext'

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="regentoren.eu.auth0.com"
      clientId="EtObxpeUgdr6CwZZViwVQ4xoDRQuRckd"
      redirectUri={window.location.origin}
      audience={'https://envlog.eemcs.utwente.nl/'}
    >
      <ToastContainer />
      <AppWrapper>
        <App />
      </AppWrapper>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
