import React, { useEffect, useState } from 'react'

export const BuienradarWidget = ({ width }: { width: number }) => {
    const height = width
    const mapString = 'https://gadgets.buienradar.nl/gadget/zoommap/?lat=52.35667&lng=6.6625&overname=2&zoom=8&naam=almelo&size=2&voor=1'

    const [showMap, setShowMap] = useState(false)

    useEffect(() => {
        fetch(mapString)
            .then(r => r.text())
            .then(r => {
                setShowMap(true)
                console.log('Buienradar widget can be loaded')
                return r
            })
            .catch(e => {
                console.log('Buienradar cannot be loaded')
                console.error(e)
            })
    }, [])

    if (showMap === false) return <React.Fragment />

    return (
        <iframe src={mapString} title={'buienradarmap'} scrolling={'no'} width={width} height={height} frameBorder={'no'}></iframe >
    )
}