import { useEffect, useState } from "react"

export const useWindowScroll = () => {
    const [positionX, updatePositionX] = useState(0)

    useEffect(() => {
        if (!window) return
        window.addEventListener('scroll', () => {
            if (!window.scrollY) return
            updatePositionX(window.scrollY)
        })
    }, [])

    return { positionX }
}