import { useMemo, useState } from 'react'
import { IDevice, useAppContext } from '../context/AppContext'
import { colors } from '../styles/colors'
import styles from './DeviceAlias.module.css'
import { EditAlias } from './EditAlias'


export const DeviceAlias = ({ currentDevice }: { currentDevice: IDevice }) => {
    const { updateSingleDevice } = useAppContext()
    const [showEditAlias, setShowEditAlias] = useState(false)
    const currentAlias = useMemo(() => {
        if (!currentDevice.alias || currentDevice.alias === '') return 'No custom name set'
        return currentDevice.alias
    }, [currentDevice])

    if (updateSingleDevice && showEditAlias === true) return (
        <EditAlias
            initialAlias={currentDevice.alias}
            deviceId={currentDevice.id}
            callBackAfterEdit={(updatedAlias: string) => {
                setShowEditAlias(false)
                const updatedCurrentDevice = {
                    ...currentDevice,
                    alias: updatedAlias
                }
                updateSingleDevice(updatedCurrentDevice)
            }}
            callBackCancel={() => setShowEditAlias(false)}
        />
    )
    return (
        <div className={styles.mainContainer}>
            <div>
                <h3 style={{ color: colors.primary }}><span>Device name: </span>{currentAlias}</h3>
                <p style={{ color: colors.secondary }}>id: {currentDevice.id}</p>
            </div>
            <button onClick={() => setShowEditAlias(true)}>Rename your device</button>
        </div>
    )
}