import { useScreenDimensions } from "../hooks/useScreenDimensions"

export const VideoPitch = () => {
    const { isSmartphone } = useScreenDimensions()

    return (
        <div style={{ margin: '0 auto', padding: isSmartphone ? 0 : '0 15vw' }}>
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                    title={'regentoren introduction video'}
                    src="https://player.vimeo.com/video/612745773?h=6bed24ca79&color=C6804E&title=0&portrait=0"
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder={0}
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                >
                </iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
    )
}