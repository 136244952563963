interface IGrafanaUrls {
    deviceId: number
    gaugueLevelUrl: string
    gaugeTemperatureUrl: string
    graphLevelUrl: string
    graphTemperatureUrl: string
}

const GRAFANA_URLS: IGrafanaUrls[] = [
    {
        deviceId: 253369,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=12',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=6',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=8',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=10'
    },
    {
        deviceId: 253388,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=4',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=13',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=14',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=15'
    },
    {
        deviceId: 253389,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=16',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=17',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=18',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=19'
    },
    {
        deviceId: 253390,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=20',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=21',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=22',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?from=START_TIME&orgId=1&to=STOP_TIME&panelId=23'
    },
    {
        deviceId: 253391,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=24',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=25',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=28',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=29'
    },
    {
        deviceId: 253392,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=36',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=37',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=38',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=39'
    },
    {
        deviceId: 253393,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=26',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=37',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=31',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=30'
    },
    {
        deviceId: 253394,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=40',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=41',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=42',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=43'
    },
    {
        deviceId: 253395,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=54',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=55',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=52',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=53'
    },
    {
        deviceId: 253396,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=58',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=59',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=57',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=56'
    },
    {
        deviceId: 253397,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=44',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=33',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=34',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=35'
    },
    {
        deviceId: 253398,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=64',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=45',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=61',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=61'
    },
    {
        deviceId: 253400,
        gaugueLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=62',
        gaugeTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=63',
        graphLevelUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=51',
        graphTemperatureUrl: 'https://envlog.eemcs.utwente.nl/grafana_public/d-solo/YZIevKF7z/public-dashboard?orgId=1&from=START_TIME&to=STOP_TIME&panelId=46'
    }
]

const updateTimeStampsInUrls = (grafanaUrls: IGrafanaUrls): IGrafanaUrls => {
    let grafanaUrlsCopy = grafanaUrls
    const now = new Date()
    let twoWeeksAgo = new Date()
    var timeInMilliseconds = twoWeeksAgo.getTime()
    // 14 days in milliseconds
    timeInMilliseconds -= 14 * 24 * 60 * 60 * 1000
    twoWeeksAgo.setTime(timeInMilliseconds)

    grafanaUrlsCopy.gaugueLevelUrl = grafanaUrlsCopy.gaugueLevelUrl.replace('START_TIME', twoWeeksAgo.valueOf().toString()).replace('STOP_TIME', now.valueOf().toString())
    grafanaUrlsCopy.gaugeTemperatureUrl = grafanaUrlsCopy.gaugeTemperatureUrl.replace('START_TIME', twoWeeksAgo.valueOf().toString()).replace('STOP_TIME', now.valueOf().toString())
    grafanaUrlsCopy.graphLevelUrl = grafanaUrlsCopy.graphLevelUrl.replace('START_TIME', twoWeeksAgo.valueOf().toString()).replace('STOP_TIME', now.valueOf().toString())
    grafanaUrlsCopy.graphTemperatureUrl = grafanaUrlsCopy.graphTemperatureUrl.replace('START_TIME', twoWeeksAgo.valueOf().toString()).replace('STOP_TIME', now.valueOf().toString())

    return grafanaUrlsCopy
}

export const getGrafanaIframeUrls = (deviceId: number): undefined | IGrafanaUrls => {
    let deviceUrls = GRAFANA_URLS.find(url => url.deviceId === deviceId)
    if (!deviceUrls) return undefined
    return updateTimeStampsInUrls(deviceUrls)
}