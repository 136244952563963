import styles from './Sponsors.module.css'

const sponsorsList = [
  { title: 'Enschede Gemeente', logoUrl: '/images/logo-enschede.png', description: '' },
  { title: 'Twente Universiteit', logoUrl: '/images/logo-twente.png', description: '' },
  { title: 'Vechtstromen', logoUrl: '/images/logo-vechtstromen.png', description: '' },
  { title: 'Zoetwatervoorziening Oost-Nederland', logoUrl: '/images/logo-zon.png', description: '' }
]

export const Sponsors = () => {
  return (
    <div className={styles.container}>
      {sponsorsList.map(sponsor => (
        <div className={styles.sponsorContainer} key={sponsor.title}>
          <img
            src={sponsor.logoUrl}
            alt={`${sponsor.title}-logo`}
          />
          <p>{sponsor.description}</p>
        </div>
      ))}
    </div>
  )
}
