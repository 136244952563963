import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

interface ILanguage {
    title: string;
    i18nValue: string;
    icon: string;
}

const languages: ILanguage[] = [
    { title: 'nederlands', i18nValue: 'nl', icon: '/images/netherlands.svg' },
    { title: 'english', i18nValue: 'en', icon: '/images/united-kingdom.svg' }
]

export const LanguageSwitch = () => {
    const { i18n } = useTranslation()
    const [showAllLanguage, setShowAllLanguages] = useState(false)
    const currentLanguage: ILanguage | undefined = useMemo(() => languages.find(l => l.i18nValue === i18n.language), [i18n.language])

    return (
        <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.2rem' }}>
            <img
                onClick={() => setShowAllLanguages(!showAllLanguage)}
                style={{ cursor: 'pointer' }}
                src={currentLanguage?.icon}
                alt={currentLanguage?.title}
            />
            {showAllLanguage && languages.filter(l => l.i18nValue !== i18n.language).map(language => (
                <img
                    onClick={() => {
                        i18n.changeLanguage(language.i18nValue)
                        setShowAllLanguages(false)
                    }}
                    style={{ cursor: 'pointer', margin: '0 0.2rem' }}
                    src={language.icon}
                    alt={`${language.title}-flag`}
                />
            ))}
        </div>
    )
}