export const LoadingIcon = ({ height = 50, width = 50 }: { height?: number, width?: number }) => {
  return (
    <img
      style={{ height: `${height}px`, width: `${width}px` }}
      src={'/images/tail-spin.svg'}
      alt={'loading'}
    />
  )
}

const loadingPageStyle = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const LoadingPage = () => {
  return (
    <div style={loadingPageStyle}>
      <LoadingIcon />
    </div>
  )
}
