import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useScreenDimensions } from "../hooks/useScreenDimensions"
import { colors } from "../styles/colors"
import styles from './OurMission.module.css'
import { Section } from "./Section"

const MobileMission = () => {
    const { t } = useTranslation('common')
    return (
        <Section title={t('mission.title')}>
            <p style={{ whiteSpace: 'break-spaces', lineHeight: '1.8rem' }}>
                {t('mission.statement')}
            </p>
            <a href={'mailto:y.m.e.latzer@utwente.nl'}>
                <button>{t('mission.contact')}</button>
            </a>
        </Section>
    )
}

const DesktopMission = () => {
    const SENTENCE_INTERVAL_MS = 5000

    const { t } = useTranslation('common')
    const [textOpacity, updateTextOpacity] = useState(0)
    const missionSentences: string[] = useMemo(() => {
        if (!t) return []
        let missionStatement = t('mission.statement')
        return missionStatement.split('\n')
    }, [t])
    const maxMissionSentencesLength = useMemo(() => missionSentences.length, [missionSentences])
    const initialMissionSentenceIndex = 0
    const [currentMissionSentenceIndex, updateCurrentMissionSentenceIndex] = useState(initialMissionSentenceIndex)
    const currentMissionSentence = useMemo(() => missionSentences[currentMissionSentenceIndex], [currentMissionSentenceIndex, missionSentences])

    useEffect(() => {
        setTimeout(() => {
            updateTextOpacity(1)
        }, 200)
        setTimeout(() => {
            updateCurrentMissionSentenceIndex(initialMissionSentenceIndex + 1)
        }, SENTENCE_INTERVAL_MS)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            updateTextOpacity(0)
        }, SENTENCE_INTERVAL_MS - 500)
        setTimeout(() => {
            updateTextOpacity(1)
        }, SENTENCE_INTERVAL_MS + 500)
        const timeout = setTimeout(() => {
            if (maxMissionSentencesLength - 1 <= 0) {
                updateCurrentMissionSentenceIndex(currentMissionSentenceIndex + 1)
            } else if (currentMissionSentenceIndex === maxMissionSentencesLength - 1) {
                updateCurrentMissionSentenceIndex(0)
            } else {
                updateCurrentMissionSentenceIndex(currentMissionSentenceIndex + 1)
            }
        }, SENTENCE_INTERVAL_MS)
        return () => clearTimeout(timeout)
    }, [currentMissionSentenceIndex, maxMissionSentencesLength])

    return (
        <section
            style={{
                backgroundColor: colors.primary,
                marginTop: 0,
                padding: '2rem 1rem',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }}
            className={styles.missionSection}
        >
            <h1
                style={{
                    fontSize: '2rem',
                    opacity: textOpacity,
                    whiteSpace: 'break-spaces',
                    transition: `opacity ${SENTENCE_INTERVAL_MS / 10}ms linear`,
                    textShadow: 'rgb(0 0 0 / 10%) 0px 5px 15px'
                }}
            >
                {currentMissionSentence}
            </h1>
            <a href={'mailto:y.m.e.latzer@utwente.nl'}>
                <button>{t('mission.contact')}</button>
            </a>
        </section>
    )
}

export const OurMission = () => {

    const { isDesktop } = useScreenDimensions()

    if (isDesktop) return <DesktopMission />
    return <MobileMission />

}