import { toast } from "react-toastify"
import { DischargeEvent } from '../components/DischargePlanner'
import { deleteSingleDischarge, getAllDischargeEvents, postNewUserDischarge } from './networkRequest'

const DEFAULT_TIMEZONE = 'GMT+0100'

export const addZero = (number: number): string => {
    return number < 10 ? `0${number}` : number.toString()
}

export const formatDateRow = (date: Date): string => {
    return `${date.toLocaleDateString()}  - ${addZero(date.getHours())}:${addZero(date.getMinutes())}`
}

export const convertDateToDateInputFormat = (date: Date): string => {
    return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`
}

export const converTimeToTimeInputFormat = (date: Date): string => {
    return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`
}

export const formatDateToPostEvent = (date: Date): string => {
    const seconds = '00'
    const timezone = date.toString().match(/([-\+][0-9]+)\s/)
    let timezoneText = DEFAULT_TIMEZONE
    if (timezone && timezone[1]) {
        timezoneText = timezone[1]
    }
    return `${convertDateToDateInputFormat(date)}:${converTimeToTimeInputFormat(date)}:${seconds}${timezoneText}`
}

export const postDischargeNewEvent = async (deviceId: number, date: Date, dischargeUntilPercentage: number, onSuccessCallBack: () => void) => {
    return postNewUserDischarge(deviceId, formatDateToPostEvent(date), dischargeUntilPercentage)
        .then(() => toast.success(`New discharge planned for device with id: ${deviceId}`))
        .then(() => onSuccessCallBack())
        .catch(e => {
            console.error(e)
            toast.warn('Discharge not planned: Something went wrong')
        })
}

export const deleteDischargeEvent = async (eventId: number, onSuccessCallBack: () => void) => {
    return deleteSingleDischarge(eventId)
        .then(() => toast.success(`Event with id: ${eventId} deleted`))
        .then(() => onSuccessCallBack())
        .catch(e => {
            console.error(e)
            toast.warn('Event could not be deleted: Something went wrong')
        })
}

export const fetchDischargeEvents = async (deviceId: number, setIsEvenLoading: (isLoading: boolean) => void, updateCurrentDeviceFutureEvents: (e: DischargeEvent[]) => void) => {
    setIsEvenLoading(true)
    return getAllDischargeEvents(deviceId)
        .then(response => response.json())
        .then(response => {
            if (!response || !response.length || response.length === 0) return
            const dischargeEvents: DischargeEvent[] = response.map((event: any) => {
                if (event.is_processed === false) {
                    return {
                        id: event.id,
                        date: new Date(event.ts),
                        dischargeUntilPercentage: event.value,
                        eventTypeId: event.event_type_id
                    }
                } else {
                    return undefined
                }
            })
            updateCurrentDeviceFutureEvents(dischargeEvents.filter(e => e !== undefined))
        })
        .catch(e => {
            console.error(e)
            toast.warn('Something went wrong while fetching discharges')
        })
        .finally(() => setIsEvenLoading(false))
}