import { useAuth0 } from "@auth0/auth0-react"
import React from "react"
import { FullPageLoadingIcon } from "../App"
import { BuienradarWidget } from '../components/BuienradarWidget'
import { SmartRainBuffer } from "../components/SmartRainBuffer"
import { TopMenu } from "../components/TopMenu"
import { useAppContext } from "../context/AppContext"
import { colors } from "../styles/colors"


const UserDashboard = () => {
    const { isAuthenticated, user } = useAuth0()
    const { isLoading, devices } = useAppContext()

    if (!isAuthenticated || !user) return (
        <main>
            <p>Please log in to access this page</p>
        </main>
    )

    return (
        <main>
            <TopMenu />
            <h2 style={{ color: colors.secondary, textAlign: 'center' }}>Welcome {user.name}</h2>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <BuienradarWidget width={256} />
            </div>
            {isLoading && <FullPageLoadingIcon size={'sm'} />}
            <React.Fragment>
                {devices && devices.length !== 0 && devices.map(device => (
                    <SmartRainBuffer key={device.id} currentDevice={device} />
                ))}
            </React.Fragment>
        </main>
    )
}

export default UserDashboard