import { useTranslation } from 'react-i18next'
import { Intro } from '../components/Intro'
import { MediaGallery } from '../components/MediaGallery'
import { OurMission } from '../components/OurMission'
import { RegentorenData } from '../components/RegentorenData'
import { Section } from '../components/Section'
import { Sponsors } from '../components/Sponsors'
import { TopMenu } from '../components/TopMenu'
import { VideoPitch } from '../components/VideoPitch'
import { colors } from '../styles/colors'

function Home() {
    const { t } = useTranslation('common')

    return (
        <main>
            <TopMenu />
            <Intro />
            <OurMission />
            <Section
                title={t('numbers.title')}
                colorBackground={false}
                titlePlacement={'inside'}
            >
                <RegentorenData />
            </Section>
            <Section
                title={t('media.title')}
                colorBackground={false}
                titlePlacement={'inside'}
            >
                <div style={{ marginBottom: '2rem' }}>
                    <p style={{ color: colors.primary, fontWeight: 500 }}>
                        {t('media.video-intro')}
                    </p>
                    <VideoPitch />
                    <p style={{ color: colors.primaryAccent, fontSize: '0.8rem' }}>
                        {t('media.video-copyright')}
                    </p>
                </div>
                <MediaGallery />
            </Section>
            <Section title={t('partners.title')}>
                <Sponsors />
            </Section>
        </main>
    )
}

export default Home
