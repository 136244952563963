import { Link } from "react-router-dom"
import { colors } from "../styles/colors"

const DefaultErrorPage = () => {
    return (
        <main style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <h2
                style={{
                    width: '100%',
                    textAlign: 'center',
                    color: colors.secondary,
                    margin: '5vh 0 0 0'
                }}>
                404: Page not found
            </h2>
            <Link to={'/'}>
                <button>Back to home</button>
            </Link>
        </main>
    )
}

export default DefaultErrorPage