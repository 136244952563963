import React, { useEffect, useMemo, useState } from 'react'
import { colors } from '../styles/colors'
import styles from './Intro.module.css'
import { useScreenDimensions } from '../hooks/useScreenDimensions'
import { useTranslation } from 'react-i18next'

export const Intro = () => {
  const { width } = useScreenDimensions()
  const height = useMemo(() => {
    if (!width) return 720
    return (416 * width) / 848
  }, [width])
  const [videoWrapperOpacity, updateVideoWrapperOpacity] = useState(0)
  const { t } = useTranslation('common')

  useEffect(() => {
    setTimeout(() => updateVideoWrapperOpacity(1), 100)
    if (!document || !document.querySelector) return
    const video = document.querySelector('video')
    if (!video) return
    video.playbackRate = 0.8
  }, [])

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <div className={`${styles.logoContainer}`}>
        <div className={'flexRow'}>
          <img
            className={styles.logo}
            src={'/images/logo.svg'}
            alt={'regentoren-logo'}
          />
          <h1 style={{ color: colors.primary }}>
            regentoren
          </h1>
        </div>
        <p style={{ color: 'white', opacity: 0.8 }}>{t('subtitle')}</p>
      </div>
      <hr />
      <div className={styles.videoWrapper}>
        <video
          style={{ opacity: videoWrapperOpacity, transition: 'opacity 2s ease-in' }}
          width={width} height={height}
          poster={'/images/loading.jpg'}
          controls={false} autoPlay muted loop playsInline
        >
          <source src={'/videos/clouds_apple1080p_weboptimized.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}
