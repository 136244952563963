import React from 'react'
import { colors } from '../styles/colors'
import styles from './Section.module.css'

const boxShadowValue = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'

interface ISectionProps {
  title: string;
  titlePlacement?: 'inside' | 'outside';
  colorBackground?: boolean;
  children?: React.ReactNode;
}

export const Section = ({ title, colorBackground = true, children, titlePlacement = 'outside' }: ISectionProps) => {
  return (
    <section>
      {titlePlacement === 'outside' &&
        <h2
          className={styles.sectionTitle}
          style={{ color: colors.secondary }}
        >
          {title}
        </h2>
      }
      <div
        className={styles.sectionContent}
        style={{
          backgroundColor: colorBackground ? colors.primary : 'transparent',
          boxShadow: colorBackground ? boxShadowValue : 'none'
        }}
      >
        {titlePlacement === 'inside' &&
          <h2
            className={styles.sectionTitle}
            style={{ color: colors.secondary }}
          >
            {title}
          </h2>
        }
        {children}
      </div>
    </section>
  )
}
