import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '../styles/colors'
import { getRegentorenInformation } from '../utils/networkRequest'
import styles from './RegentorenData.module.css'

const DataSquare = ({ number, subtitle, isLoading }: { number: number, subtitle: string, isLoading: boolean }) => {
  const numberFontSizeInRem = useMemo(() => {
    const subtitleLetterNumber = number.toString().length
    switch (subtitleLetterNumber) {
      default:
      case 2:
        return 3
      case 3:
        return 2.5
      case 4:
        return 2.2
    }
  }, [number])

  return (
    <div className={styles.squareContainer}>
      <p>{subtitle}</p>
      <div className={styles.square}>
        {isLoading
          ?
          <img style={{ width: '50%', height: '50%' }} src={'/images/loading.svg'} alt={'loading-icon'} />
          :
          <h3 style={{ fontSize: `${numberFontSizeInRem}rem` }}>{number}</h3>
        }
      </div>
    </div>
  )
}

export const RegentorenData = () => {
  const [deviceNumber, updateDeviceNumber] = useState(-999)
  const [regentorenCapacity, updateRegentorenCapacity] = useState(-999)
  const [regentorenFillLevel, updateRegentorenFillLevel] = useState(-999)
  const [dataTimestamp, updateDataTimestamp] = useState('')

  const regentorenCapacityLeft = useMemo(() => regentorenCapacity - regentorenFillLevel, [regentorenCapacity, regentorenFillLevel])
  const isLoading = useMemo(() => regentorenCapacity === -999, [regentorenCapacity])

  const { t } = useTranslation('common')

  useEffect(() => {
    getRegentorenInformation()
      .then(response => response.json())
      .then(response => {
        if (!response || !response.ts || !response.current_capacity || !response.current_fill_level || !response.devicesNumber) return
        updateDeviceNumber(response.devicesNumber)
        updateRegentorenCapacity(response.current_capacity)
        updateRegentorenFillLevel(response.current_fill_level)
        const timestamp = new Date(response.ts)
        updateDataTimestamp(timestamp.toLocaleString())
      })
      .catch(error => console.error(error))
  }, [])

  return (
    <div className={styles.container}>
      <DataSquare number={regentorenCapacity} subtitle={t('numbers.max-volume')} isLoading={isLoading} />
      <DataSquare number={regentorenCapacityLeft} subtitle={t('numbers.volume')} isLoading={isLoading} />
      <DataSquare number={deviceNumber} subtitle={t('numbers.devices')} isLoading={isLoading} />

      <div className={styles.timestamp} style={{ color: colors.secondary }}>
        {dataTimestamp && <p>{t('numbers.last-update')}: {dataTimestamp}</p>}
      </div>
    </div>
  )
}
