import React, { useEffect, useMemo, useState } from "react"
import { colors } from "../styles/colors"
import { convertDateToDateInputFormat, converTimeToTimeInputFormat, deleteDischargeEvent, fetchDischargeEvents, formatDateRow, postDischargeNewEvent } from '../utils/dischargePlanner'
import styles from './DischargePlanner.module.css'
import { LoadingIcon } from "./Loading"

export interface DischargeEvent {
    id: number
    date: Date
    dischargeUntilPercentage: number
    eventTypeId?: number
}

export const DischargePlanner = ({ deviceId }: { deviceId: number }) => {
    const initialEvents: DischargeEvent[] = []
    const initalDeletedEventIds: number[] = []
    const [deletedEventIds, updateDeletedEventIds] = useState(initalDeletedEventIds)
    const [currentDeviceFutureEvents, updateCurrentDeviceFutureEvents] = useState(initialEvents)
    const filteredFutureEvents = useMemo(() => {
        return currentDeviceFutureEvents.filter(event => deletedEventIds.findIndex(deleteEventId => deleteEventId === event.id) === -1)
    }, [deletedEventIds, currentDeviceFutureEvents])
    const [isEventsLoading, setIsEvenLoading] = useState(false)
    const [showPlanner, setShowPlanner] = useState(false)

    useEffect(() => {
        fetchDischargeEvents(deviceId, setIsEvenLoading, updateCurrentDeviceFutureEvents)
    }, [deviceId])

    const Planning = () => {
        const initialEventIdToDelete = -1
        const [eventIdToDelete, updateEventIdToDelete] = useState(initialEventIdToDelete)
        return (
            <React.Fragment>
                {filteredFutureEvents.map(event => (
                    <div className={styles.planningRow}>
                        <p>{formatDateRow(event.date)}</p>
                        <div className={styles.dischargeRow}>
                            <p>Discharge until
                                <span style={{ color: colors.primary, marginLeft: '0.25rem' }}>{event.dischargeUntilPercentage}%</span>
                            </p>
                            <p
                                className={styles.cancelDischargeButton}
                                onClick={() => updateEventIdToDelete(event.id)}
                            >
                                ❌
                            </p>
                        </div>
                        {eventIdToDelete && eventIdToDelete === event.id &&
                            <>
                                <p>Are you sure you want to delete this discharge?</p>
                                <div className={styles.cancelYesNoRow}>
                                    <p
                                        style={{ color: colors.primary }}
                                        onClick={() => {
                                            const successCallBack = () => {
                                                updateEventIdToDelete(initialEventIdToDelete)
                                                updateDeletedEventIds([...deletedEventIds, event.id])
                                            }
                                            return deleteDischargeEvent(event.id, successCallBack)
                                        }}
                                    >
                                        yes
                                    </p>
                                    <p
                                        style={{ color: colors.secondary }}
                                        onClick={() => updateEventIdToDelete(initialEventIdToDelete)}
                                    >
                                        no
                                    </p>
                                </div>
                            </>
                        }
                    </div>
                ))}
            </React.Fragment>
        )
    }

    const Planner = () => {
        const INITIAL_DISCHARGE_PERCENTAGE = 25
        const [dischargePercentage, updateDischargePercentage] = useState(INITIAL_DISCHARGE_PERCENTAGE)
        const isDischargePercentageValid = useMemo(() => {
            if (isNaN(dischargePercentage) === true || dischargePercentage === null) return false
            if (dischargePercentage > 100 || dischargePercentage < 0) return false
            return true
        }, [dischargePercentage])

        const INITIAL_DISCHARGE_DATE = new Date()
        const [dischargeDate, updateDischargeDate] = useState(INITIAL_DISCHARGE_DATE)

        return (
            <React.Fragment>
                <input
                    type={'date'}
                    value={convertDateToDateInputFormat(dischargeDate)}
                    onChange={e => {
                        const newDate = new Date(e.target.value)
                        let dateCopy = new Date(dischargeDate)
                        dateCopy.setFullYear(newDate.getFullYear())
                        dateCopy.setMonth(newDate.getMonth())
                        dateCopy.setDate(newDate.getDate())
                        return updateDischargeDate(dateCopy)
                    }}
                />
                <input
                    type={'time'}
                    value={converTimeToTimeInputFormat(dischargeDate)}
                    onChange={e => {
                        const newDate = e.target.value.split(':')
                        let dateCopy = new Date(dischargeDate)
                        dateCopy.setHours(parseInt(newDate[0]))
                        dateCopy.setMinutes(parseInt(newDate[1]))
                        updateDischargeDate(dateCopy)
                    }}
                />
                <div className={styles.percentageInput}>
                    <p>Discharge until:</p>
                    <input
                        type={'number'}
                        value={dischargePercentage}
                        onChange={e => updateDischargePercentage(parseInt(e.target.value))}
                        style={{ borderColor: isDischargePercentageValid ? 'unset' : 'red' }}
                    />
                    <span>%</span>
                    <span style={{fontWeight: 'bold', margin: '0.1rem'}} onClick={() => updateDischargePercentage(dischargePercentage + 25)}>+</span>
                    <span style={{fontWeight: 'bold', margin: '0.1rem'}} onClick={() => updateDischargePercentage(dischargePercentage - 25)}>-</span>
                </div>
                {isDischargePercentageValid === false &&
                    <p style={{ fontSize: '0.8rem', color: 'red' }}>⚠️ invalid percentage</p>
                }
                <button
                    onClick={() => setShowPlanner(false)}
                    style={{ color: colors.secondary, position: 'relative', marginTop: '0.5rem' }}
                >
                    back
                </button>
                <button
                    onClick={async () => {
                        const successCallBack = () => {
                            fetchDischargeEvents(deviceId, setIsEvenLoading, updateCurrentDeviceFutureEvents)
                                .finally(() => setShowPlanner(false))
                        }
                        await postDischargeNewEvent(deviceId, dischargeDate, dischargePercentage, successCallBack)
                    }}
                    disabled={isDischargePercentageValid === false}
                    style={{
                        position: 'relative',
                        marginTop: '0.5rem',
                        opacity: isDischargePercentageValid ? 1 : 0.2,
                        cursor: isDischargePercentageValid ? 'pointer' : 'not-allowed'
                    }}
                >
                    save
                </button>
            </React.Fragment>
        )
    }

    if (isEventsLoading) return <LoadingIcon />

    return (
        <div className={styles.mainContainer}>
            <h4 style={{ color: colors.primary }}>{showPlanner ? 'Plan a new discharge' : 'Planned discharges'}</h4>
            <p
                style={{ color: colors.secondary, fontWeight: 'bold' }}
                className={styles.switchButton}
                onClick={() => setShowPlanner(!showPlanner)}
            >
                {showPlanner ? 'List planned discharges' : 'Plan a new discharge'}
            </p>
            <div className={styles.planningContainer}>
                {showPlanner
                    ?
                    <Planner />
                    :
                    <Planning />
                }
            </div>
        </div>
    )
}
