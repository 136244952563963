import { useAuth0 } from "@auth0/auth0-react"
import { useMemo } from "react"
import { Link, useLocation } from "react-router-dom"
import { useScreenDimensions } from "../hooks/useScreenDimensions"
import { colors } from "../styles/colors"
import styles from './LoginMenu.module.css'

interface IMenuButtonProps {
    title: string;
    onClick?: () => void;
    color?: string;
    isSmartphone: boolean | undefined;
}

const MenuButton = ({ title, onClick, color, isSmartphone }: IMenuButtonProps) => {
    const iconUrl = useMemo(() => {
        switch (title) {
            default:
            case 'dashboard':
            case 'login':
                return '/images/person.svg'
            case 'logout':
                return '/images/box-arrow-right.svg'
        }
    }, [title])

    if (isSmartphone) return (
        <img
            alt={`${title}-icon`}
            src={iconUrl}
            onClick={() => {
                if (!onClick) return
                return onClick()
            }}
            style={{
                height: '1rem',
                width: '1rem',
                color: 'white',
                backgroundColor: color ? color : colors.secondary,
                margin: '0 0.25rem',
                padding: '0.25rem',
                borderRadius: '0.25rem',
                cursor: 'pointer'
            }}
        />
    )

    return (
        <p
            onClick={() => {
                if (!onClick) return
                return onClick()
            }}
            style={{
                color: 'white',
                backgroundColor: color ? color : colors.secondary,
                fontWeight: 'bold',
                margin: '0 0.25rem',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.25rem'
            }}
        >
            {title}
        </p>
    )
}

export const LoginMenu = () => {
    const { isAuthenticated, logout, loginWithRedirect } = useAuth0()
    const { isSmartphone } = useScreenDimensions()
    const { pathname } = useLocation()
    const isHome = useMemo(() => pathname === '/', [pathname])

    return (
        <div className={styles.container}>
            {isAuthenticated &&
                <Link to={isHome ? '/user-dashboard' : '/'}>
                    <MenuButton color={colors.primary} title={isHome ? 'dashboard' : 'home'} isSmartphone={isSmartphone} />
                </Link>
            }
            {isAuthenticated
                ?
                <MenuButton onClick={() => logout({ returnTo: window.location.origin })} title={'logout'} isSmartphone={isSmartphone} />
                :
                <MenuButton onClick={() => loginWithRedirect()} title={'login'} isSmartphone={isSmartphone} />
            }
        </div>
    )
}