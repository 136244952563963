import React, { useMemo, useState } from "react"
import { IDevice, useAppContext } from "../context/AppContext"
import { useScreenDimensions } from "../hooks/useScreenDimensions"
import { colors } from "../styles/colors"
import { getGrafanaIframeUrls } from "../utils/grafanaIframeUrls"
import { DeviceAlias } from './DeviceAlias'
import { DischargePlanner } from "./DischargePlanner"
import styles from './SmartRainBuffer.module.css'

export const SmartRainBuffer = ({ currentDevice }: { currentDevice: IDevice }) => {
    const { width, height, isSmartphone } = useScreenDimensions()
    const { devices, updateSingleDevice } = useAppContext()
    const [showAllVisualization, setShowAllVisualization] = useState(false)
    const gaugeWidth: number = useMemo(() => {
        if (!width) return 128
        return isSmartphone ? (width / 2) - 20 : width / 4
    }, [width, isSmartphone])
    const gaugeHeight = useMemo(() => gaugeWidth - 50, [gaugeWidth])
    const graphWidth = useMemo(() => {
        if (!width) return 128
        return isSmartphone ? width - 32 : width - 64
    }, [width, isSmartphone])
    const graphHeight = useMemo(() => {
        if (!height) return 128
        return height / 3
    }, [height])
    const deviceGrafanaIframeUrl = useMemo(() => {
        return getGrafanaIframeUrls(currentDevice.id)
    }, [currentDevice.id])

    if (!devices || !updateSingleDevice || !currentDevice) return <React.Fragment />

    return (
        <div className={styles.container}>
            <DeviceAlias currentDevice={currentDevice} />
            {deviceGrafanaIframeUrl &&
                <div
                    className={styles.visualizationContainer}
                >
                    <div className={styles.gaugeContainer}>
                        <iframe
                            title={`${currentDevice.id}-gauge-level`}
                            src={deviceGrafanaIframeUrl.gaugueLevelUrl}
                            width={`${gaugeWidth}px`}
                            height={`${gaugeHeight}px`}
                            frameBorder="0"
                        >
                        </iframe>
                        <iframe
                            title={`${currentDevice.id}-gauge-temperature`}
                            src={deviceGrafanaIframeUrl.gaugeTemperatureUrl}
                            width={`${gaugeWidth}px`}
                            height={`${gaugeHeight}px`}
                            frameBorder="0"
                        >
                        </iframe>
                    </div>
                    <DischargePlanner deviceId={currentDevice.id} />
                    <button
                        className={styles.showMoreButton}
                        onClick={() => setShowAllVisualization(!showAllVisualization)}
                    >
                        show {showAllVisualization ? 'less' : 'more'}
                    </button>
                    {showAllVisualization &&
                        <div className={styles.lineChartContainer}>
                            <iframe
                                title={`${currentDevice.id}-graph-level`}
                                src={deviceGrafanaIframeUrl.graphLevelUrl}
                                width={`${graphWidth}px`}
                                height={`${graphHeight}px`}
                                frameBorder="0"
                            >
                            </iframe>
                            <iframe
                                title={`${currentDevice.id}-graph-temperature`}
                                src={deviceGrafanaIframeUrl.graphTemperatureUrl}
                                width={`${graphWidth}px`}
                                height={`${graphHeight}px`}
                                frameBorder="0"
                            >
                            </iframe>

                        </div>
                    }
                </div>
            }
            <div className={styles.separator} style={{ backgroundColor: colors.primary }} />
        </div>
    )
}