import { Suspense, useMemo } from "react"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import DefaultErrorPage from "./pages/DefaultErrorPage"
import Home from "./pages/Home"
import UserDashboard from "./pages/UserDashboard"
import { colors } from "./styles/colors"

export const FullPageLoadingIcon = ({ size = 'md' }: { size?: 'sm' | 'md' | 'xl' }) => {
  const loaderSize = useMemo(() => {
    switch (size) {
      default:
      case 'md': return '25%'
      case 'sm': return '15%'
      case 'xl': return '50%'
    }
  }, [size])

  return (
    <div style={{ backgroundColor: colors.primaryAccent, width: '100vw', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img style={{ width: loaderSize, height: loaderSize }} src={'/images/loading.svg'} alt={'loading-icon'} />
    </div>
  )
}

function App() {
  return (
    <Router>
      <Suspense fallback={<FullPageLoadingIcon size={'xl'} />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/user-dashboard" component={UserDashboard} />
          <Route path="*" component={DefaultErrorPage} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
