import { useAuth0 } from '@auth0/auth0-react'
import { createContext, useContext, useEffect, useState } from 'react'
import { getAllUserSrbs } from '../utils/networkRequest'

export interface IDevice {
  id: number;
  alias: string;
  sensorsystem_type_id: number;
}

interface AppState {
  isLoading: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  devices?: IDevice[];
  deviceIds?: number[];
  updateDevices?: (devices: IDevice[]) => void;
  updateSingleDevice?: (deviceToUpdate: IDevice) => void;
  fetchSingleDevice?: (deviceId: number) => void
}

const initialState: AppState = { isLoading: false }
const AppContext = createContext(initialState)

export const AppWrapper = ({ children }: { children: any }) => {
  const { user } = useAuth0()
  const [isLoading, setIsLoading] = useState(initialState.isLoading)
  const initalDevicesList: IDevice[] = []
  const [devices, updateDevices] = useState(initalDevicesList)

  useEffect(() => {
    if (devices?.length === 0) {
      if (!setIsLoading) return
      if (!user || !user.sub || user.sub === '') return
      console.log('Getting all SRB from user...')
      setIsLoading(true)
      getAllUserSrbs(user.sub)
        .then((response: any) => response.json())
        .then((response: any) => {
          console.log(response)
          return response
        })
        .then((response: any) => updateDevices(response.devices))
        .catch((e: any) => console.error(e))
        .finally(() => setIsLoading(false))
    }
  }, [user, setIsLoading, devices])

  const updateSingleDevice = (deviceToUpdate: IDevice) => {
    return updateDevices([
      ...devices.filter(d => d.id !== deviceToUpdate.id),
      deviceToUpdate
    ])
  }

  return (
    <AppContext.Provider
      value={{ isLoading, setIsLoading, devices, updateDevices, updateSingleDevice }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
