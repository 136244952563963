import { useState } from 'react'
import { toast } from 'react-toastify'
import { colors } from '../styles/colors'
import { updateSrbAlias } from '../utils/networkRequest'
import styles from './EditAlias.module.css'

interface IEditAliasProps {
  initialAlias: string
  deviceId: number
  callBackAfterEdit: (value: string) => void
  callBackCancel: () => void
}

export const EditAlias = ({ initialAlias, deviceId, callBackAfterEdit, callBackCancel }: IEditAliasProps) => {
  const [alias, updateAlias] = useState(initialAlias)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className={styles.mainContainer}>
      <h3 style={{ color: colors.primary }}>New device name:</h3>
      {isLoading
        ?
        <p>saving...</p>
        :
        <div>
          <input
            type={'text'}
            value={alias}
            onChange={(event => updateAlias(event.target.value))}
          />
          <button onClick={() => {
            if (alias === initialAlias) return toast.warn('Alias did not change')
            setIsLoading(true)
            updateSrbAlias(deviceId, alias)
              .then(() => {
                setIsLoading(false)
                callBackAfterEdit(alias)
                toast.success(`Alias for sensosytem ${deviceId} was updated`)
              })
              .catch((error) => {
                setIsLoading(false)
                toast.error('Something went wrong while updating the alias')
                updateAlias(initialAlias)
                console.error(error)
              })
          }}>
            save
          </button>
          <button onClick={() => callBackCancel()}>cancel</button>
        </div>
      }
    </div>
  )
}
